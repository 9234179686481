import React, { ReactElement } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Layout } from '../components/common';
import { StyledButton } from '../components/common/Buttons';
import ContentHero from '../components/common/contentSections/ContentHero';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import { Image } from '../utils/image';

const ContactUs = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="Get In Touch With Us"
                desc="We are here to help you build identity management for your business. Tell us what you want to achieve and we will help you. Want to schedule a call with StackIAM, click here to schedule a meeting with our representative"
                imageUrl="/images/contact-us-hero.svg"
            />
            <Row
                style={{
                    paddingTop: `3rem`,
                    paddingBottom: `3rem`,
                    color: `#535b6c`,
                    fontSize: `1.25rem`,
                    backgroundColor: `#f6f8fb`,
                }}
                noGutters
            >
                <Col lg="4" md="12">
                    <div style={{ display: `flex`, justifyContent: `center` }}>
                        <Image
                            style={{ height: `2rem`, width: `2rem` }}
                            src="/images/contact-us-mail.svg"
                        />
                        <div style={{ paddingLeft: `0.5rem` }}>
                            contact@stackiam.com
                        </div>
                    </div>
                </Col>
                <Col lg="4" md="12">
                    <div style={{ display: `flex`, justifyContent: `center` }}>
                        <Image
                            style={{ height: `2rem`, width: `2rem` }}
                            src="/images/contact-us-location-pin.svg"
                        />
                        <div style={{ paddingLeft: `0.5rem` }}>
                            42, Mongomery road Sabo yaba, Lagos Nigeria
                        </div>
                    </div>
                </Col>
                <Col lg="4" md="12">
                    <div style={{ display: `flex`, justifyContent: `center` }}>
                        <Image
                            style={{ height: `2rem`, width: `2rem` }}
                            src="/images/contact-us-mobile.svg"
                        />
                        <div style={{ paddingLeft: `0.5rem` }}>
                            +2348027272720
                        </div>
                    </div>
                </Col>
            </Row>
            <HeadingSection heading="We’d love to hear from you." />
            <Row noGutters style={{ padding: `2rem` }}>
                <Col lg="6" md="12">
                    <Form.Group
                        style={{ paddingBottom: `1rem`, paddingRight: `1rem` }}
                        controlId="formBasicFullName"
                    >
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                            style={{ borderRadius: `1rem` }}
                            type="text"
                            placeholder="Full Name"
                        />
                    </Form.Group>
                </Col>
                <Col lg="6" md="12">
                    <Form.Group
                        style={{ paddingBottom: `1rem`, paddingRight: `1rem` }}
                        controlId="formBasicEmail"
                    >
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            style={{ borderRadius: `1rem` }}
                            type="email"
                            placeholder="Email Address"
                        />
                    </Form.Group>
                </Col>
                <Col lg="6" md="12">
                    <Form.Group
                        style={{ paddingBottom: `1rem`, paddingRight: `1rem` }}
                        controlId="formBasicPhone"
                    >
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            style={{ borderRadius: `1rem` }}
                            type="text"
                            placeholder="Phone Number"
                        />
                    </Form.Group>
                </Col>
                <Col lg="6" md="12">
                    <Form.Group
                        style={{ paddingBottom: `1rem`, paddingRight: `1rem` }}
                        controlId="formBasicOrganizationName"
                    >
                        <Form.Label>Organization Name</Form.Label>
                        <Form.Control
                            style={{ borderRadius: `1rem` }}
                            type="text"
                            placeholder="Organization Name"
                        />
                    </Form.Group>
                </Col>
                <Col xs="12">
                    <Form.Group
                        style={{ paddingBottom: `1rem`, paddingRight: `1rem` }}
                        controlId="formBasicCountry"
                    >
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            style={{ borderRadius: `1rem` }}
                            type="text"
                            placeholder="Country"
                        />
                    </Form.Group>
                </Col>
                <Col xs="12">
                    <Form.Group
                        style={{ paddingBottom: `1rem`, paddingRight: `1rem` }}
                        controlId="contactForm.MessageContent"
                    >
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                            style={{ borderRadius: `1rem` }}
                            as="textarea"
                            rows={10}
                        />
                    </Form.Group>
                </Col>
                <Col
                    xs="12"
                    style={{ display: `flex`, justifyContent: `center` }}
                >
                    <StyledButton style={{ marginRight: 0 }}>
                        Send Message
                    </StyledButton>
                </Col>
            </Row>
        </Container>
    </Layout>
);

export default ContactUs;
